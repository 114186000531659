import React, { useState, useContext } from 'react'
import classNames from 'classnames'
import { forbidScroll, allowScroll } from '../../../utils/utils'
import { providerContext } from '../../Provider'
import Button from 'components/Base/Button'
import { fetchLogout } from 'api/user'
import * as styles from './index.module.less'

const Header: React.FC = (props) => {
  const { user } = useContext(providerContext)
  const [visible, setVisible] = useState(false)

  const handleLogAuto = async () => {
    if (user) {
      const res = await fetchLogout()
      const { status } = res.data
      if (status === 200) {
        window.location.reload()
      }
    } else {
      const dom = document.createElement('div')
      dom.setAttribute('id', 'sensors-login')
      document.body.appendChild(dom)
      window['LoginDialogComponent']?.render(
        {
          lang: 'zh-TW', // 语言环境，默认中文简体：zh-CN，繁体：zh-TW
          title: '欢迎登录瓦利数据',
          visible: false, // 在初始化时控制组件显示和隐藏，默认false
          domainPassword: process.env.API_URL, // 登录接口域名配置，可不传，默认：/api/passport，测试环境可配置：/api/_passport
          // domainSchool: 'https://school.sensorsdata.cn',  // 学堂接口域名配置，可不传，默认：https://school.sensorsdata.cn
          config: {
            channel: '', // 渠道字段，
            requestBtn: '', // 按钮名
            formpage_source: '', // 注册表单类型，可选值：视频、demo、文档、预约、白皮书
            document_name: '', // 注册文档名称
            video_name: '', // 注册视频名称
          },
          onLoginSuccess: function () {
            window.location.reload()
          }, // 登录成功回调事件
          onShow: function () {
            forbidScroll()
          }, // 弹窗显示时回调事件
          onHide: function () {
            allowScroll()
            dom.remove()
          }, // 弹窗关闭时回调事件
        },
        dom,
      )
      setTimeout(() => {
        window['LoginDialogComponent']?.show()
      }, 100)
    }
  }

  return (
    <div className={classNames(styles.header)}>
      <div id="loginDialogReact" />
      <div className={classNames(styles.placeholder, styles.fixed)}>
        <nav className={classNames(styles.navbar)}>
          <div className={classNames(styles.logo)} />
          <div className={classNames(styles.navWrapper, 'pc-show')}>
            <ul className={classNames(styles.navPc)}>
              <li>
                <a href="/#section_01 " className={classNames(styles.pcNavLi)}>
                  产品
                </a>
              </li>
              <li>
                <a href="/#section_02 " className={classNames(styles.pcNavLi)}>
                  行业方案
                </a>
              </li>
              <li>
                <a href="/#section_03 " className={classNames(styles.pcNavLi)}>
                  关于我们
                </a>
              </li>
              <li className={classNames(styles.button)}>
                <Button onClick={handleLogAuto} btnType="default">
                  {user ? '注销' : '立即注册'}
                </Button>
              </li>
            </ul>
          </div>
          <div className={classNames(styles.navWrapper, 'm-show')}>
            <div
              className={classNames(styles.menuBtn)}
              onClick={() => {
                setVisible(!visible)
              }}
            >
              <span className={classNames(styles.iconBar)} />
              <span className={classNames(styles.iconBar)} />
              <span className={classNames(styles.iconBar)} />
            </div>
            <ul
              className={classNames(styles.navMobile, {
                [styles.navShow]: visible,
              })}
            >
              <li>
                <a href="/#section_01 " className={classNames(styles.mNavLi)}>
                  产品
                </a>
              </li>
              <li>
                <a href="/#section_02 " className={classNames(styles.mNavLi)}>
                  行业方案
                </a>
              </li>
              <li>
                <a href="/#section_03 " className={classNames(styles.mNavLi)}>
                  关于我们
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default Header
