import React from "react";
import classnames from "classnames";
import Button, { ButtonGroup, ButtonType } from 'components/Base/Button';
import * as styles from "./index.module.less";

interface FooterProps {
  className?: string;
}
const Footer: React.FC<FooterProps> = (props) => {
  const { className } = props;
  return (
    <footer className={classnames(styles.WaLiFooter, className)}>
      <div className={classnames(styles.dividerLine)} />
      <ButtonGroup className={styles.FooterButtonGroup}>
        <Button
          size='small' 
          btnType={ButtonType.Link}
          href="/app/calendar.html">随身小日历</Button>
      </ButtonGroup>
      <article>
        <p className="sd-font-16">瓦利网络科技（上海）有限公司</p>
        <p className="sd-font-16">电话：021-62341035</p>
        <p className="sd-font-16">
          地址：中国（上海）自由贸易试验区华京路 8 号 3 层 333 室
        </p>
        <p className="sd-font-16">
          <a
            href="https://beian.miit.gov.cn"
            target="_blank"
            style={{ color: "#1f2d3d" }}
          >
            <img
              src={require("assets/images/wali/website_filling.jpg").default}
              alt=""
            />
            沪ICP备2021007615号
          </a>
        </p>
        <div className={classnames(styles.iconLicence)}>
          <img
            src={require("assets/images/wali/icon-licence.png").default}
            alt=""
            style={{ verticalAlign: "sub" }}
          />
        </div>
        <p className="sd-font-16">20210422103702517</p>
      </article>
    </footer>
  );
};

export default Footer;
