import React from "react";
import Layout from "components/WaLi/Layout";
import classNames from "classnames";
import * as styles from "./index.module.less";

const Calendar: React.FC = (props) => {
  return (
    <Layout
      title="随身小日历"
    >
      <main className={classNames(styles.content)}>
        <div className={classNames(styles.calendarWrapper)}>
          <div className={classNames(styles.calendarDes)}>
            <div className={classNames(styles.calendarIcon)} />
            <div className={classNames(styles.descText)}>
              <h2>随身小日历</h2>
              <p>
                让生活更有序，让工作更高效；
                <br /> 随身小日历帮助用户更好地管理时间；
                <br />
                事件提醒功能操作简单，使用便捷；
                <br /> 推荐小文章温暖用户的心。
              </p>
            </div>
          </div>
          <div className={classNames(styles.calendarDetail)}>
            <img src={require("assets/images/wali/calendar-01.png").default} />
            <img src={require("assets/images/wali/calendar-02.png").default} />
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default Calendar;
