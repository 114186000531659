import React from 'react'
import { Helmet } from "react-helmet";
import Header from '../Header'
import Footer from '../Footer'
import * as styles from "./index.module.less";

interface LayoutProps {
  title?: string;
  description?: string;
  keywords?: string;
  saTitle?: string;
}
const Layout: React.FC<LayoutProps> = props => {
  const {
    children,
    title,
    description,
    keywords,
    saTitle
  } = props;
  return (
    <>
      <Helmet 
        title={title}
        meta={[
          {
            name: `description`,
            content: description,
          },
          {
            name: `keywords`,
            content: keywords
          },
          {
            name: `sa-title`,
            content: saTitle
          }
        ]}
      />
      <Header />
      {children}
      <Footer />
    </>
  );
}

export default Layout;